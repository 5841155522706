import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { theme } from '../../theme/theme';

const MountainScene = () => {
  const sceneRef = useRef(null);
  const mountainsRef = useRef(null);
  const starsRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      // Animate stars twinkling
      gsap.to('.star', {
        opacity: 0.2,
        stagger: {
          each: 0.3,
          repeat: -1,
          yoyo: true
        },
        duration: 1.5
      });

      // Parallax effect on mountains
      gsap.to(mountainsRef.current, {
        y: -30,
        scrollTrigger: {
          trigger: sceneRef.current,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1
        }
      });

      // Floating clouds
      gsap.to('.cloud', {
        x: '+=20',
        y: '+=10',
        rotation: 5,
        duration: 3,
        yoyo: true,
        repeat: -1,
        ease: 'power1.inOut',
        stagger: {
          each: 0.5,
          from: 'random'
        }
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <div
      ref={sceneRef}
      className="relative h-[400px] overflow-hidden rounded-2xl"
    >
      {/* Stars */}
      <div ref={starsRef} className="absolute inset-0">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="star absolute w-1 h-1 rounded-full bg-white"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              opacity: Math.random() * 0.8 + 0.2
            }}
          />
        ))}
      </div>

      {/* Mountains */}
      <div ref={mountainsRef} className="absolute bottom-0 w-full">
        <svg
          viewBox="0 0 1000 400"
          preserveAspectRatio="none"
          className="w-full h-full"
        >
          <path
            d="M0 400 L250 100 L500 300 L750 50 L1000 400 Z"
            fill={theme.colors.slate.primary}
            opacity="0.3"
          />
          <path
            d="M0 400 L400 150 L600 350 L1000 400 Z"
            fill={theme.colors.moss.primary}
            opacity="0.4"
          />
          <path
            d="M0 400 L300 200 L700 300 L1000 400 Z"
            fill={theme.colors.stone.primary}
            opacity="0.5"
          />
        </svg>
      </div>

      {/* Clouds */}
      {[...Array(5)].map((_, i) => (
        <div
          key={i}
          className="cloud absolute"
          style={{
            left: `${20 + i * 20}%`,
            top: `${20 + i * 10}%`,
            opacity: 0.6
          }}
        >
          <div
            className="w-16 h-6 rounded-full"
            style={{ backgroundColor: theme.colors.mist.primary }}
          />
        </div>
      ))}

      {/* Northern Lights Effect */}
      <div
        className="absolute inset-0 opacity-30"
        style={{
          background: `linear-gradient(45deg, 
            ${theme.colors.moss.primary}00 0%, 
            ${theme.colors.moss.primary}40 50%, 
            ${theme.colors.moss.primary}00 100%
          )`,
          animation: 'aurora 8s infinite'
        }}
      />
    </div>
  );
};

export default MountainScene;
