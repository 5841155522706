const ProfileImage = ({ imageRef }) => (
  <div
    ref={imageRef}
    className="relative w-48 h-48 md:w-64 md:h-64 mx-auto mb-16 opacity-0"
  >
    <img
      src="https://firebasestorage.googleapis.com/v0/b/blogify-9a1bd.appspot.com/o/mee%20crop.jpg?alt=media&token=397c6463-12b2-4d2c-b222-92028b80d231"
      alt="Profile"
      className="rounded-full object-cover w-full h-full border-4 border-purple-400/50 shadow-lg"
    />
    <div className="absolute inset-0 rounded-full border-4 border-purple-400/30 animate-pulse" />
    <div className="absolute -inset-4 bg-purple-500/20 rounded-full blur-xl -z-10" />
  </div>
);

export default ProfileImage;
