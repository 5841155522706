export const naturalColors = {
  stone: {
    primary: '#D4C4B0', // Lighter warm brown
    secondary: '#BBA794' // Lighter earth
  },
  moss: {
    primary: '#9CAE9E', // Lighter forest green
    secondary: '#7B917D' // Lighter moss
  },
  slate: {
    primary: '#A5B4BC', // Lighter mountain rock
    secondary: '#8FA0A9' // Lighter slate
  },
  mist: {
    primary: '#D5DDE0', // Lighter mountain mist
    secondary: '#B4C5CE' // Lighter cool grey
  }
};
