import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const useHeroAnimations = (
  headingRef,
  subHeadingRef,
  descriptionRef,
  carRef
) => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      // Animate heading
      gsap.from(headingRef.current, {
        y: 100,
        opacity: 0,
        duration: 1,
        ease: 'power4.out'
      });

      // Animate subheading
      gsap.from(subHeadingRef.current, {
        y: 50,
        opacity: 0,
        duration: 1,
        delay: 0.3,
        ease: 'power4.out'
      });

      // Animate description
      gsap.from(descriptionRef.current, {
        y: 50,
        opacity: 0,
        duration: 1,
        delay: 0.6,
        ease: 'power4.out'
      });

      // Animate car movement
      gsap.to(carRef.current, {
        x: '100vw',
        duration: 15,
        repeat: -1,
        ease: 'none'
      });
    });

    return () => ctx.revert();
  }, [headingRef, subHeadingRef, descriptionRef, carRef]);
};

export default useHeroAnimations;
