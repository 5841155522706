import React from 'react';

const ProjectImage = ({ image, theme }) => (
  <div className="relative px-4 sm:px-8 md:px-12 lg:px-0">
    {/* Browser Window Mockup */}
    <div className="relative rounded-xl overflow-hidden shadow-2xl">
      {/* Browser Header */}
      <div className="flex items-center gap-2 px-3 py-2 md:px-4 md:py-3 bg-slate-800/80 backdrop-blur-sm">
        <div className="flex gap-1 md:gap-1.5">
          <div className="w-2 h-2 md:w-3 md:h-3 rounded-full bg-red-400" />
          <div className="w-2 h-2 md:w-3 md:h-3 rounded-full bg-yellow-400" />
          <div className="w-2 h-2 md:w-3 md:h-3 rounded-full bg-green-400" />
        </div>
      </div>

      {/* Browser Content */}
      <div className="relative aspect-[16/10]">
        <img
          src={image}
          alt="Project Preview"
          className="w-full h-full object-cover"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-gradient-to-br from-slate-900/10 to-slate-900/30" />
      </div>
    </div>

    {/* Decorative Elements */}
    <div
      className="absolute -inset-4 blur-2xl -z-10 opacity-40 md:opacity-60"
      style={{ backgroundColor: `${theme.color}10` }}
    />
    <div
      className="absolute -inset-4 blur-3xl -z-20 opacity-30 md:opacity-40"
      style={{ backgroundColor: `${theme.color}05` }}
    />
  </div>
);

export default ProjectImage;
