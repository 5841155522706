import React, { useRef } from 'react';
import ProfileImage from './ProfileImage';
import AboutContent from './AboutContent';
import ParallaxMountains from './ParallaxMountains';
import useAboutAnimations from '../../hooks/useAboutAnimations';
import { theme } from '../../theme/theme';

const AboutSection = () => {
  const sectionRef = useRef(null);
  const imageRef = useRef(null);
  const contentRef = useRef(null);
  const overlayRef = useRef(null);
  const mountainsRef = useRef(null);

  useAboutAnimations(
    sectionRef,
    imageRef,
    contentRef,
    overlayRef,
    mountainsRef
  );

  return (
    <section
      ref={sectionRef}
      className="min-h-screen relative flex flex-col justify-center py-20 overflow-hidden"
      style={{ background: theme.gradients.background }}
    >
      <div
        className="absolute inset-0 bg-cover bg-center opacity-50"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1506905925346-21bda4d32df4?auto=format&fit=crop&q=80&w=2000')"
        }}
      />
      <ParallaxMountains mountainsRef={mountainsRef} />
      <div
        ref={overlayRef}
        className="absolute inset-0 opacity-0"
        style={{ background: theme.gradients.heroOverlay }}
      />
      <ProfileImage imageRef={imageRef} />
      <AboutContent contentRef={contentRef} />
    </section>
  );
};

export default AboutSection;
