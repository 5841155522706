import { useRef } from 'react';
import HeroContent from './HeroContent';
import MovingCar from '../MovingCar';
import useHeroAnimations from '../../hooks/useHeroAnimations';
import { theme } from '../../theme/theme';
import Background from '../Background/Background';

const HeroSection = () => {
  const headingRef = useRef(null);
  const subHeadingRef = useRef(null);
  const descriptionRef = useRef(null);
  const carRef = useRef(null);

  useHeroAnimations(headingRef, subHeadingRef, descriptionRef, carRef);

  return (
    <div
      className="relative h-screen w-screen overflow-hidden"
      style={{ background: theme.gradients.background }}
    >
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?auto=format&fit=crop&q=80&w=2000')",
          opacity: 0.75
        }}
      />
      <div
        className="absolute inset-0"
        style={{ background: theme.gradients.heroOverlay }}
      />
      <Background />
      <MovingCar carRef={carRef} />
      <HeroContent
        headingRef={headingRef}
        subHeadingRef={subHeadingRef}
        descriptionRef={descriptionRef}
      />
    </div>
  );
};

export default HeroSection;
