import React from 'react';
import { Car } from 'lucide-react';

const MovingCar = ({ carRef }) => (
  <div
    ref={carRef}
    className="fixed bottom-[3%] md:bottom-[4%] left-[-50px] transform -translate-y-1/2 z-50"
  >
    <Car size={32} className="text-[#BBA794]" />
  </div>
);

export default MovingCar;
