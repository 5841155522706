import React from 'react';
import TextReveal from './TextReveal';
import { theme } from '../../theme/theme';

const AboutContent = ({ contentRef }) => (
  <div ref={contentRef} className="relative z-10 max-w-6xl mx-auto px-4">
    <div className="grid md:grid-cols-2 gap-12 items-center">
      <div className="space-y-8">
        <TextReveal>
          <h2
            className="text-4xl md:text-6xl font-bold bg-clip-text text-transparent"
            style={{ backgroundImage: theme.gradients.textHighlight }}
          >
            Crafting Digital Adventures
          </h2>
        </TextReveal>

        <TextReveal>
          <p
            className="text-xl md:text-2xl leading-relaxed"
            style={{ color: theme.colors.mist.primary }}
          >
            From pixels to mountain peaks, I blend the art of coding with the
            thrill of exploration. Every project is a new summit to conquer,
            every line of code a step towards the next horizon.
          </p>
        </TextReveal>

        <TextReveal>
          <div className="flex flex-wrap gap-4">
            {[
              'Full Stack Developer',
              'Mountain Explorer',
              'Adventure Seeker'
            ].map(tag => (
              <span
                key={tag}
                className="px-4 py-2 rounded-full backdrop-blur-sm"
                style={{
                  backgroundColor: `${theme.colors.moss.primary}20`,
                  color: theme.colors.moss.primary
                }}
              >
                {tag}
              </span>
            ))}
          </div>
        </TextReveal>
      </div>

      <div className="relative">
        <TextReveal>
          <div
            className="relative z-10 p-8 rounded-2xl backdrop-blur-sm border"
            style={{
              background: `linear-gradient(to br, ${theme.colors.slate.primary}20, ${theme.colors.moss.primary}20)`,
              borderColor: `${theme.colors.moss.primary}20`
            }}
          >
            <p
              className="text-lg md:text-xl leading-relaxed mb-6"
              style={{ color: theme.colors.mist.primary }}
            >
              "In the world of technology, I'm not just a developer – I'm an
              explorer charting new territories. Each project is a unique
              journey, combining technical expertise with creative vision to
              craft experiences that leave a lasting impact."
            </p>
            <p
              className="text-lg md:text-xl leading-relaxed"
              style={{ color: theme.colors.mist.primary }}
            >
              "When I'm not coding, you'll find me chasing sunrises from
              mountaintops, where the air is thin but dreams soar high. This
              balance of digital craft and outdoor adventure fuels my creative
              approach to problem-solving."
            </p>
          </div>
        </TextReveal>
      </div>
    </div>
  </div>
);

export default AboutContent;
