import Road from './Road';

const Background = () => (
  <div className="absolute inset-0 w-full h-full">
    <svg
      className="fixed z-50 bottom-0 w-full h-[80px] md:h-[100px]"
      viewBox="0 0 1920 100"
      preserveAspectRatio="none"
    >
      <Road />
    </svg>
  </div>
);

export default Background;
