import React from 'react';

const Road = () => (
  <>
    {/* Main road */}
    <path
      d="M0 30 C480 20, 960 40, 1920 30 L1920 100 L0 100 Z"
      fill="#374151"
    />
    {/* Road markings */}
    <path
      d="M0 65 L1920 65"
      stroke="#ffffff"
      strokeWidth="4"
      strokeDasharray="30,30"
      className="road-line"
    />
    {/* Side details */}
    <path d="M0 30 L1920 30" stroke="#4B5563" strokeWidth="2" />
  </>
);

export default Road;
