import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const useContactAnimations = (sectionRef, contentRef) => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      // Fade in and slide up animation for the content
      gsap.from(contentRef.current, {
        y: 100,
        opacity: 0,
        duration: 1,
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top center',
          end: 'center center',
          scrub: 1
        }
      });

      // Animate contact info items
      const contactItems = contentRef.current.querySelectorAll('.contact-item');
      contactItems.forEach((item, index) => {
        gsap.from(item, {
          x: -50,
          opacity: 0,
          duration: 0.6,
          delay: 0.2 * index,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top center',
            toggleActions: 'play none none reverse'
          }
        });
      });

      // Animate social icons with stagger
      const socialIcons = contentRef.current.querySelectorAll('.social-icon');
      gsap.from(socialIcons, {
        scale: 0,
        opacity: 0,
        duration: 0.5,
        stagger: 0.1,
        ease: 'back.out(1.7)',
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top center',
          toggleActions: 'play none none reverse'
        }
      });
    });

    return () => ctx.revert();
  }, [sectionRef, contentRef]);
};

export default useContactAnimations;
