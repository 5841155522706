import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const useProjectsAnimation = (sectionRef, containerRef) => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      // Calculate total scroll distance based on number of projects
      const totalProjects = containerRef.current.children[0].children.length;
      const scrollDistance = totalProjects * 100;

      // Create the horizontal scroll animation
      gsap.to(containerRef.current.children[0], {
        x: () =>
          -(containerRef.current.children[0].scrollWidth - window.innerWidth),
        ease: 'none',
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top top',
          end: `+=${scrollDistance}%`,
          pin: true,
          scrub: 1,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          snap: {
            snapTo: 1 / (totalProjects - 1),
            duration: { min: 0.2, max: 0.3 },
            delay: 0,
            ease: 'power1.inOut'
          }
        }
      });
    });

    return () => ctx.revert();
  }, [sectionRef, containerRef]);
};

export default useProjectsAnimation;
