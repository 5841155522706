import React, { useRef } from 'react';
import ProjectShowcase from './ProjectShowcase';
import SectionHeading from './SectionHeading';
import useProjectsAnimation from '../../hooks/useProjectsAnimation';
import { projects } from '../../data/projects';

const ProjectsSection = () => {
  const sectionRef = useRef(null);
  const containerRef = useRef(null);

  useProjectsAnimation(sectionRef, containerRef);

  return (
    <section
      ref={sectionRef}
      className="relative min-h-screen overflow-hidden"
      style={{
        background:
          'linear-gradient(to bottom, rgba(44, 62, 80, 0.9), rgba(26, 38, 52, 0.95))'
      }}
    >
      {/* Mountain background */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-fixed opacity-30"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?auto=format&fit=crop&q=80&w=2000')"
        }}
      />

      <SectionHeading />

      <div ref={containerRef} className="absolute inset-0">
        <div className="flex">
          {projects.map(project => (
            <ProjectShowcase key={project.id} project={project} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
