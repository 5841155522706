const ParallaxMountains = ({ mountainsRef }) => (
  <div ref={mountainsRef} className="absolute inset-0 pointer-events-none">
    <svg
      className="absolute w-full h-full opacity-40"
      viewBox="0 0 1920 1080"
      preserveAspectRatio="xMidYMid slice"
    >
      <defs>
        <linearGradient id="mountainGrad" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop
            offset="0%"
            style={{ stopColor: '#2d3748', stopOpacity: 0.8 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: '#1a202c', stopOpacity: 0.3 }}
          />
        </linearGradient>
      </defs>
      <path
        className="mountain-1"
        d="M-200 1080 L400 200 L1000 1080 Z"
        fill="url(#mountainGrad)"
      />
      <path
        className="mountain-2"
        d="M400 1080 L1200 300 L2000 1080 Z"
        fill="url(#mountainGrad)"
      />
      <path
        className="mountain-3"
        d="M800 1080 L1600 100 L2400 1080 Z"
        fill="url(#mountainGrad)"
      />
    </svg>
  </div>
);

export default ParallaxMountains;
