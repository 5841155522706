import { Mountain, Code2, Compass, Map } from 'lucide-react';
import { theme } from '../../theme/theme';

const HeroContent = ({ headingRef, subHeadingRef, descriptionRef }) => (
  <div className="relative z-10 flex flex-col items-center justify-center h-full text-white px-4 text-center">
    <h1
      ref={headingRef}
      className="text-4xl md:text-7xl font-bold mb-4 md:mb-6 tracking-wider bg-clip-text text-transparent"
      style={{ backgroundImage: theme.gradients.textHighlight }}
    >
      Shivam Anand
    </h1>

    <div
      ref={subHeadingRef}
      className="flex flex-col items-center gap-3 mb-6 md:mb-8"
    >
      <p className="text-lg md:text-2xl flex flex-wrap justify-center items-center gap-2 md:gap-4 font-light">
        <Code2
          style={{ color: theme.colors.moss.primary }}
          className="w-5 h-5 md:w-6 md:h-6"
        />
        <h2 style={{ color: theme.colors.stone.primary }}>
          Full Stack Developer
        </h2>
        <span
          style={{ color: theme.colors.slate.primary }}
          className="hidden md:inline"
        >
          •
        </span>
        <Mountain
          style={{ color: theme.colors.moss.primary }}
          className="w-5 h-5 md:w-6 md:h-6"
        />
        <span style={{ color: theme.colors.stone.primary }}>
          Mountain Explorer
        </span>
      </p>
      <p className="text-base md:text-xl flex flex-wrap justify-center items-center gap-2 md:gap-4 font-light">
        <Map
          style={{ color: theme.colors.moss.primary }}
          className="w-5 h-5 md:w-6 md:h-6"
        />
        <span style={{ color: theme.colors.mist.primary }}>
          Navigating through code and mountain trails
        </span>
        <Compass
          style={{ color: theme.colors.moss.primary }}
          className="w-5 h-5 md:w-6 md:h-6"
        />
      </p>
    </div>

    <div ref={descriptionRef} className="max-w-2xl space-y-4 md:space-y-6 px-2">
      <p
        className="text-base md:text-xl leading-relaxed"
        style={{ color: theme.colors.mist.primary }}
      >
        Turning{' '}
        <span style={{ color: theme.colors.moss.primary }}>{'<code />'}</span>{' '}
        into digital peaks, and conquering real summits on weekends. Every road
        trip is a new git branch, every mountain a production deploy.
      </p>
      <p
        className="text-sm md:text-base italic"
        style={{ color: theme.colors.stone.primary }}
      >
        "Debug by day, drive by sunset, dream by starlight"
      </p>
    </div>
  </div>
);

export default HeroContent;
