import { naturalColors } from './colors';

export const theme = {
  colors: naturalColors,
  gradients: {
    background: 'linear-gradient(to bottom, #2C3E50, #1A2634)',
    heroOverlay: `linear-gradient(to bottom, rgba(44, 62, 80, 0.8), rgba(26, 38, 52, 0.9))`,
    textHighlight: `linear-gradient(to right, ${naturalColors.stone.primary}, ${naturalColors.moss.primary}, ${naturalColors.slate.primary})`
  },
  shadows: {
    subtle: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    medium: '0 10px 15px -3px rgba(0, 0, 0, 0.2)',
    strong: '0 20px 25px -5px rgba(0, 0, 0, 0.3)'
  }
};
