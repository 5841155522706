import React, { useRef } from 'react';
import ContactInfo from './ContactInfo';
import useContactAnimations from '../../hooks/useContactAnimations';
import { theme } from '../../theme/theme';
import MountainScene from './MountainScene';

const ContactSection = () => {
  const sectionRef = useRef(null);
  const contentRef = useRef(null);

  useContactAnimations(sectionRef, contentRef);

  return (
    <section
      ref={sectionRef}
      className="relative min-h-screen flex items-center py-20 overflow-hidden"
      style={{ background: theme.gradients.background }}
    >
      <div
        className="absolute inset-0 bg-cover bg-center opacity-20"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1454496522488-7a8e488e8606?auto=format&fit=crop&q=80&w=2000')"
        }}
      />

      <div
        className="absolute inset-0"
        style={{
          background:
            'linear-gradient(to bottom, rgba(26, 32, 44, 0.95), rgba(17, 24, 39, 0.98))'
        }}
      />

      <div
        ref={contentRef}
        className="relative z-10 w-full max-w-6xl mx-auto px-4"
      >
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
            <span
              className="bg-clip-text text-transparent"
              style={{ backgroundImage: theme.gradients.textHighlight }}
            >
              Let's Connect
            </span>
          </h2>
          <p
            className="text-lg md:text-xl"
            style={{ color: theme.colors.mist.primary }}
          >
            Reach out and let's create something amazing together
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-12 lg:gap-16 items-center">
          <ContactInfo />
          <MountainScene />
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
