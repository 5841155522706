import React from 'react';
import { Mail, LinkedinIcon, InstagramIcon } from 'lucide-react';
import { theme } from '../../theme/theme';

const ContactInfo = () => {
  const contactDetails = [
    {
      icon: Mail,
      text: 'shivamanand252@gmail.com',
      href: 'mailto:shivamanand252@gmail.com'
    },
    {
      icon: InstagramIcon,
      text: '@er_shivam_anand',
      href: 'https://www.instagram.com/er_shivam_anand/'
    },
    {
      icon: LinkedinIcon,
      text: 'shivamanand-dev',
      href: 'https://www.linkedin.com/in/shivamanand-dev/'
    }
    // { icon: MapPin, text: 'Mountain View, CA', href: '#' }
  ];

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        {contactDetails.map(({ icon: Icon, text, href }) => (
          <a
            key={text}
            href={href}
            className="contact-item flex items-center gap-4 text-lg group"
            style={{ color: theme.colors.mist.primary }}
          >
            <Icon
              className="w-6 h-6"
              style={{ color: theme.colors.moss.primary }}
            />
            <span className="transition-colors group-hover:text-white">
              {text}
            </span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ContactInfo;
