import React from 'react';
import { ArrowRight } from 'lucide-react';
import ProjectFeatures from './ProjectFeatures';
import ProjectImage from './ProjectImage';

const ProjectShowcase = ({ project }) => {
  if (!project || !project.theme) {
    return null;
  }

  return (
    <div
      className="relative w-screen h-screen flex-shrink-0 flex items-center justify-center px-4 md:px-8"
      style={{
        background: `linear-gradient(to bottom right, 
          rgba(26, 32, 44, 0.4), 
          rgba(17, 24, 39, 0.7), 
          rgba(26, 32, 44, 0.4)
        )`
      }}
    >
      {/* Background Decorative Elements */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          backgroundImage: `
            radial-gradient(circle at 20% 20%, ${project.theme.color}20 50%, transparent 40%),
            radial-gradient(circle at 80% 80%, ${project.theme.secondary}20 50%, transparent 40%)
          `
        }}
      />

      <div className="max-w-7xl mx-auto w-full flex flex-col lg:grid lg:grid-cols-2 gap-6 lg:gap-16 items-center relative z-10">
        <div className="space-y-4 md:space-y-6 order-2 lg:order-1">
          <div>
            <h2
              className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-2"
              style={{ color: project.theme.color }}
            >
              {project.title}
              <br />
              {project.subtitle}
            </h2>
            <p
              className="text-lg md:text-xl lg:text-2xl"
              style={{ color: `${project.theme.color}CC` }}
            >
              {project.tagline}
            </p>
          </div>

          <p className="text-sm md:text-base lg:text-lg text-gray-300/90 leading-relaxed max-w-xl">
            {project.description}
          </p>

          <div className="hidden md:block">
            <ProjectFeatures
              features={project.features}
              themeColor={project.theme.color}
            />
          </div>

          <a
            href={project.demoUrl}
            target="_blank"
            className="inline-flex items-center gap-2 text-base md:text-lg group"
            style={{ color: project.theme.color }}
            rel="noreferrer"
          >
            View Demo
            <ArrowRight className="w-4 h-4 md:w-5 md:h-5 transition-transform group-hover:translate-x-1" />
          </a>
        </div>

        <div className="w-full max-w-lg lg:max-w-none order-1 lg:order-2">
          <ProjectImage image={project.image} theme={project.theme} />
        </div>
      </div>
    </div>
  );
};

export default ProjectShowcase;
