export const projects = [
  {
    id: 1,
    title: 'Leaf Lens',
    subtitle: ' ',
    tagline: 'Identify Any Plant Instantly',
    description:
      'Take a photo or upload an image of any plant, and our AI will help you identify and learn about it.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/blogify-9a1bd.appspot.com/o/Leaf-Lens.png?alt=media&token=01a623db-4a93-4feb-bf0a-c19ed154b502',
    features: [
      'Identify Plant from a picture',
      'Identify Plant from a camera',
      'Search History'
    ],
    demoUrl: 'https://leaflens.shivamanand.dev/',
    theme: {
      color: '#10B981',
      secondary: '#14B8A6'
    }
  },
  {
    id: 2,
    title: 'Analytics',
    subtitle: 'Dashboard',
    tagline: 'Data-driven decisions',
    description:
      'Powerful analytics platform with real-time insights and customizable dashboards.',
    image:
      'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=1000',
    features: ['Custom reports', 'Data visualization', 'Predictive analytics'],
    demoUrl: 'https://analytics.demo',
    theme: {
      color: '#3B82F6',
      secondary: '#06B6D4'
    }
  }
];
