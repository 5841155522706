import React from 'react';

const ProjectFeatures = ({ features, themeColor }) => (
  <ul className="space-y-4">
    {features.map(feature => (
      <li key={feature} className="flex items-center text-gray-300/80">
        <span
          className="w-2 h-2 rounded-full mr-3"
          style={{ backgroundColor: themeColor }}
        />
        {feature}
      </li>
    ))}
  </ul>
);

export default ProjectFeatures;
