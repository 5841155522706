import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

const useAboutAnimations = (
  sectionRef,
  imageRef,
  contentRef,
  overlayRef,
  mountainsRef
) => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      // Wild mountain parallax
      const mountains = mountainsRef.current.querySelectorAll('path');
      mountains.forEach((mountain, index) => {
        gsap.to(mountain, {
          y: (index + 1) * -150,
          rotate: (index - 1) * 5,
          scale: 1 + index * 0.1,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 1.5
          }
        });
      });

      // Profile image wild animation
      gsap.fromTo(
        imageRef.current,
        {
          opacity: 0,
          scale: 0.5,
          y: 200,
          rotation: -20
        },
        {
          opacity: 1,
          scale: 1,
          y: 0,
          rotation: 0,
          duration: 2,
          ease: 'elastic.out(1, 0.3)',
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top center',
            toggleActions: 'play none none reverse'
          }
        }
      );

      // Text reveal animations
      const revealTexts = contentRef.current.querySelectorAll('.reveal-text');
      revealTexts.forEach((text, index) => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: text,
            start: 'top bottom-=100',
            toggleActions: 'play none none reverse'
          }
        });

        tl.fromTo(
          text,
          {
            y: 100,
            opacity: 0,
            scale: 0.9,
            rotateX: -45
          },
          {
            y: 0,
            opacity: 1,
            scale: 1,
            rotateX: 0,
            duration: 1.5,
            ease: 'power4.out'
          }
        );
      });

      // Enhanced overlay animation
      gsap.fromTo(
        overlayRef.current,
        {
          opacity: 0,
          backdropFilter: 'blur(0px)'
        },
        {
          opacity: 0.8,
          backdropFilter: 'blur(5px)',
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top center',
            end: 'center center',
            scrub: true
          }
        }
      );
    });

    return () => ctx.revert();
  }, [sectionRef, imageRef, contentRef, overlayRef, mountainsRef]);
};

export default useAboutAnimations;
