import React from 'react';
import { naturalColors } from '../../theme/colors';

const SectionHeading = () => (
  <div className="absolute top-8 md:top-20 left-1/2 -translate-x-1/2 text-center z-10 w-full px-4">
    <h2 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-2 md:mb-4">
      <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#D4C4B0] via-[#9CAE9E] to-[#A5B4BC]">
        Projects
      </span>
    </h2>
    <p
      className="text-base md:text-xl"
      style={{ color: naturalColors.mist.primary }}
    >
      Explore my digital peaks and valleys
    </p>
  </div>
);

export default SectionHeading;
